<script lang="ts" setup>
import type { HomeBanners } from "~/types";

defineProps<{ banner: HomeBanners[number] }>();

const { refreshBonusesData } = useFlipTheCoin();
const { open } = useAppModals();

const openFlipTheCoin = () => {
	open("LazyOModalFlipTheCoin");
	refreshBonusesData();
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="openFlipTheCoin"
	>
		<template #default>
			<AText class="banner-title" variant="tuzla" :modifiers="['uppercase']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText class="banner-title" variant="tanzay" :modifiers="['bold', 'uppercase']">
				<p v-html="banner.smallTitle" />
			</AText>
		</template>

		<template #actions>
			<AButton variant="primary" class="app-banner__btn">
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ banner.buttonText }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.banner-title p {
	text-align: left;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}
</style>
